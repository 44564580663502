<template>
  <div>
    <vx-card class="mb-base">
      <div class="flex flex-wrap">
        <h2>Children's Learning Stories</h2>
        <!-- <div class="matrix-btn-wrap ml-auto mb-8">
          <span>
            <toggle-switch :options="myOptions" v-model="displayView" />
          </span>
        </div> -->
      </div>

      <!-- Five columns -->
      <vs-row class="story-grid mb-4 ls-icons">
        <div
          class="flex justify-center items-center w-full "
        >
           <div class="datepicker-wrapper hasIcon w-full lg:mb-8 ">
           <label class="block mb-2"> From</label>
            <datepicker
              :minimumView="'month'"
              :maximumView="'month'"
              icon-after="true"
              icon="calendar_today"
              icon-pack="feather"
              :format="dateFormat"
              v-model="startDate"
              class="w-full"
              placeholder="Select Start Month"
              :value="startDate"
            ></datepicker>
            <vs-icon icon="calendar_today" class="icon"></vs-icon>
          </div>
        </div>

        <div
          class="flex justify-center items-center w-full "
        >
          <div class="datepicker-wrapper hasIcon w-full lg:mb-8 ">
           <label class="block mb-2"> To</label>
            <datepicker
              :minimumView="'month'"
              :maximumView="'month'"
              icon-after="true"
              icon="calendar_today"
              icon-pack="feather"
              :format="dateFormat"
              v-model="endDate"
              class="w-full"
              placeholder="Select End Month"
              :value="endDate"
              :disabled-dates="disabledDates"
            ></datepicker>
            <vs-icon icon="calendar_today" class="icon"></vs-icon>
          </div>
        </div>

        <div
          class="flex justify-center items-center w-full "
        >
          <div class="lg:mb-8 w-full">
            <label class="block mb-2">Core skill</label>
            <v-select
              :options="coreSkillOptions"
              :clearable="false"
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
              v-model="coreSkillFilter"
              class="mb-4 md:mb-0"
              data-vv-as="Room"
            />
          </div>
        </div>

        <div
          class="flex justify-center items-center w-full "
        >
          <div class="lg:mb-8 w-full">
            <label class="block mb-2">STREAM</label>
            <v-select
              :options="streamOptions"
              :clearable="false"
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
              v-model="streamFilter"
              class="mb-4 md:mb-0"
              data-vv-as="Room"
            />
          </div>
        </div>

        <div
          class="flex justify-center items-center w-full"
        >
          <div class="lg:mb-8 w-full">
            <label class="block mb-2">Ways Of Learning</label>
            <v-select
              :options="waysOfLearningOptions"
              :clearable="false"
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
              v-model="waysOfLearningFilter"
              class="mb-4 md:mb-0"
              data-vv-as="Room"
            />
          </div>
        </div>

        <div
          class="flex justify-center items-center w-full"
        >
          <div class="lg:mb-8 w-full">
            <label class="block mb-2">Teacher</label>
            <v-select
              :options="teacherOptions"
              :clearable="false"
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
              v-model="teacherFilter"
              class="mb-4 md:mb-0"
              data-vv-as="Room"
            />
          </div>
        </div>

      </vs-row>
      <div class="overflow-auto">
        <table
          class="table children-activity-table"
          width="100%"
          v-if="displayView === 'Matrix'"
        >
          <template v-for="(stream, key) in streams">
            <tr v-if="key === 0" :key="key">
              <template v-for="(skill, ckey) in coreSkills">
                <td v-if="ckey === 0 && key === 0" :key="ckey"></td>
                <td v-if="key === 0" class="text-center" :key="ckey + 0.1">
                  <span class="img-block"
                    ><img
                      :src="skill.icon ? skill.icon : $defaultImage"
                      alt=""
                      class="product-img text-center"
                    />
                  </span>
                  {{ skill.name }}
                </td>
                <td
                  v-if="ckey > 0 && key > 0"
                  class="text-center"
                  :key="ckey + 0.2"
                ></td>
              </template>
            </tr>
            <tr :key="key + 0.2">
              <template v-for="(skill, ckey) in coreSkills">
                <td v-if="ckey === 0" class="text-center" :key="ckey">
                  <span class="img-block">
                    <img
                      :src="stream.icon ? stream.icon : $defaultImage"
                      alt=""
                      class="product-img"
                    />
                  </span>
                  {{ stream.name }}
                </td>
                <td class="text-center" :key="ckey + 0.2">
                  <ul class="status-list">
                    <li
                      v-for="(index, aKey) in activityMatrix(
                        skill._id,
                        stream._id
                      )"
                      :key="aKey"
                      style="display: inline-block"
                    >
                      <span
                        v-if="index.parentActivityId"
                        @click="showActivityDetail(index)"
                        :class="
                          index.isCompleted
                            ? 'rounded-full complete'
                            : 'rounded-full follow-up'
                        "
                        v-scroll-to="
                          index.isCompleted
                            ? { el: '#activity-detail', offset: -100 }
                            : ''
                        "
                      ></span>
                      <span
                        v-else
                        :class="'rounded-full ' + index.status[0].class"
                        @click="showActivityDetail(index)"
                        v-scroll-to="
                          index.status[0].slug === 'new'
                            ? { el: '#edit-form', offset: -100 }
                            : { el: '#activity-detail', offset: -100 }
                        "
                      ></span>
                      <vx-card
                        :class="
                          key === streams.length - 1 ||
                          key === streams.length - 2 ||
                          key === streams.length - 3 ||
                          ckey === coreSkills.length - 1
                            ? key < 3
                              ? 'vx-card-down'
                              : 'vx-card-up'
                            : ''
                        "
                      >
                        <span class="arrow"></span>

                        <h3>{{ index.name }}</h3>
                        <p>
                          {{
                            index.description.length > 160
                              ? index.description.substr(0, 160) + "..."
                              : index.description
                          }}
                        </p>
                        <ul>
                          <li>
                            <img
                              :src="
                                index.coreSkill.length &&
                                index.coreSkill[0].icon
                                  ? index.coreSkill[0].icon
                                  : $defaultImage
                              "
                              alt=""
                              class="h-16 object-contain"
                            />
                          </li>
                          <li>
                            <img
                              :src="
                                index.waysOfLearning.length &&
                                index.waysOfLearning[0].icon
                                  ? index.waysOfLearning[0].icon
                                  : $defaultImage
                              "
                              alt=""
                              class="h-16 object-contain"
                            />
                          </li>
                          <li>
                            <img
                              :src="
                                index.streams.length && index.streams[0].icon
                                  ? index.streams[0].icon
                                  : $defaultImage
                              "
                              alt=""
                              class="h-16 object-contain"
                            />
                          </li>
                        </ul>
                      </vx-card>
                    </li>
                  </ul>
                </td>
              </template>
            </tr>
          </template>
        </table>
        <table
          class="table activity-list-table"
          width="100%"
          v-if="displayView === 'List'"
        >
          <thead>
            <tr>
              <th>Learning Story</th>
              <th>Description</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(activity, key) in activitiesList" :key="key">
              <td class="font-semibold">
                <span
                  style="cursor: pointer;"
                  @click="detailPage(activity._id)"
                  v-scroll-to="{ el: '#edit-form', offset: -100 }"
                >
                  {{ activity.name }}
                </span>
              </td>
              <td>
                <p>{{ activity.description }}</p>
                <span class="inline-block text-center"
                  ><img
                    :src="
                      activity.coreSkill.length
                        ? activity.coreSkill[0].icon
                        : ''
                    "
                    alt=""
                    width="45px"
                    height="45px"
                    class="inline-block"
                  />
                  <p class="block">
                    {{
                      activity.coreSkill.length
                        ? activity.coreSkill[0].name
                        : ""
                    }}
                  </p></span
                >
                <span class="inline-block text-center"
                  ><img
                    :src="
                      activity.waysOfLearning.length
                        ? activity.waysOfLearning[0].icon
                        : ''
                    "
                    alt=""
                    width="45px"
                    height="45px;"
                    class="inline-block"
                  />
                  <p class="block">
                    {{
                      activity.waysOfLearning.length
                        ? activity.waysOfLearning[0].name
                        : ""
                    }}
                  </p></span
                >
                <span class="inline-block text-center"
                  ><img
                    :src="
                      activity.streams.length ? activity.streams[0].icon : ''
                    "
                    alt=""
                    width="45px"
                    height="45px;"
                    class="inline-block"
                  />
                  <p class="block">
                    {{
                      activity.streams.length ? activity.streams[0].name : ""
                    }}
                  </p></span
                >
              </td>
              <td>
                <template v-if="activity.parentActivityId" class="status-list">
                  <span
                    :class="
                      'rounded-full ' + activity.isCompleted
                        ? 'complete'
                        : 'follow-up'
                    "
                    style="width: 17px; height: 17px; border-radius: 50% !important; display: inline-block; margin-right: 15px; position: relative; top: 3px;"
                  ></span>
                  {{ activity.isCompleted ? "Complete" : "Follow Up" }}
                </template>
                <template v-else class="status-list">
                  <span
                    :class="'rounded-full ' + activity.status[0].class"
                    style="width: 17px; height: 17px; border-radius: 50% !important; display: inline-block; margin-right: 15px; position: relative; top: 3px;"
                  >
                  </span
                  >{{ activity.status[0].name }}
                </template>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </vx-card>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import Vue from "vue";
import VueLodash from "vue-lodash";
import lodash from "lodash";
//import DatePicker from "vue2-datepicker";
import Datepicker from "vuejs-datepicker";
import vSelect from "vue-select";
import _ from "lodash";

require("../../../../assets/scss/datepicker.scss");
Vue.use(VueLodash, { name: "custom", lodash: lodash });
// import ToggleSwitch from "vuejs-toggle-switch";

const VueScrollTo = require("vue-scrollto");
Vue.use(VueScrollTo);
// Vue.use(ToggleSwitch);

export default {
  components: {
    Datepicker,
    vSelect
  },
  data() {
    return {
      dateFormat: "MMMM",
      initialLoad: true,

      teacher: {},
      followUpData: "",
      week: new Date(),
      lang: {
        formatLocale: {
          firstDayOfWeek: 1
        },
        monthBeforeYear: false
      },
      startDate: "",
      endDate: new Date(),
      activitiesList: "",
      displayView: "List",
      selectedActivity: "",
      popupActive: false,
      myOptions: {
        layout: {
          color: "black",
          backgroundColor: "rgb(246, 246, 246)",
          borderColor: "#2c98d5",
          fontFamily: "open sans",
          fontWeight: "normal",
          // fontWeightSelected: 'bold',
          squareCorners: false,
          noBorder: false
        },
        size: {
          fontSize: 1,
          padding: 2,
          width: 20
        },
        items: {
          delay: 0.4,
          preSelected: "Matrix",
          disabled: false,
          labels: [
            { name: "Matrix", color: "white", backgroundColor: "#2898D5" },
            { name: "List", color: "white", backgroundColor: "#2898D5" }
          ]
        }
      },

      coreSkillOptions: [],
      streamOptions: [],
      waysOfLearningOptions: [],
      teacherOptions: [],
      coreSkillFilter: { label: "Select", value: "" },
      streamFilter: { label: "Select", value: "" },
      waysOfLearningFilter: { label: "Select", value: "" },
      teacherFilter: { label: "Select", value: "" },
      coreSkillValue: "",
      waysOfLearningValue: "",
      streamValue: "",
      teacherId: "",
      disabledDates: {}
    };
  },
  props: ["selectedFromPlan"],
  methods: {
    ...mapActions("activity", ["fetchOnlyActivities"]),
    ...mapActions("teacher", ["getTeacherById"]),
    ...mapActions("coreSkill", ["fetchCoreSkills"]),
    ...mapActions("stream", ["fetchStreams"]),
    ...mapActions("waysOfLearning", ["fetchWaysOfLearning"]),
    ...mapActions("center", ["getFilterTeacherList"]),


    detailPage(activityId) {
      this.$router.push({
        path: "/director/learning-story/activity/" + activityId
      });
    },
    showActivityDetail(activity) {
      this.selectedActivity = activity;
    },

    async getActivities() {
      let startDate =
        this.startDate.getFullYear() +
        "-" +
        parseInt(this.startDate.getMonth() + 1)
          .toString()
          .padStart(2, 0) +
        "-01";
      let lastDay = new Date(
        this.endDate.getFullYear(),
        this.endDate.getMonth() + 1,
        0
      );
      let endDate =
        lastDay.getFullYear() +
        "-" +
        parseInt(lastDay.getMonth() + 1)
          .toString()
          .padStart(2, 0) +
        "-" +
        lastDay.getDate();

      let param =
        "?startDate=" +
        startDate +
        "&endDate=" +
        endDate +
        "&directorId=" +
        this.$store.state.AppActiveUser._id +
        //"&filterValue=" +
        //this.filterValue +
        //"&filterType=" +
        //this.filterType +
        "&coreSkills=" +
        this.coreSkillValue +
        "&waysOfLearnings=" +
        this.waysOfLearningValue +
        "&streams=" +
        this.streamValue +
        "&status=complete" +
        "&teacherId=" +
        this.teacherId;
      this.$vs.loading();

      this.fetchOnlyActivities(param)
        .then(res => {
          this.$vs.loading.close();
          this.activitiesList = this.$store.state.activity.activities;
        })
        .catch(err => {
          this.$vs.loading.close();
          console.error(err);
        });
    },
    activityMatrix(coreSkillId, streamId) {
      return lodash.filter(this.activitiesList, {
        coreSkills: coreSkillId,
        stream: streamId
      });
    }
  },
  async mounted() {
    let today = new Date(),
      lastThreeMonths = new Date();
    lastThreeMonths.setMonth(lastThreeMonths.getMonth() - 3);
    this.startDate = lastThreeMonths;
    //----
    if (this.$route.query.displayView) {
      this.displayView = this.$route.query.displayView;
    }

    if (this.$route.query.teacher) {
      await this.getTeacherById(this.$route.query.teacher).then(res => {
        this.teacher = res.data.data;
        console.log(res.data.data.roomId, "asdasd");
      });
    }

    if (this.$route.query.coreSkill) {
      this.coreSkillValue = this.$route.query.coreSkill;
    }
    if (this.$route.query.waysOfLearning) {
      this.waysOfLearningValue = this.$route.query.waysOfLearning;
    }

    if (this.$route.query.stream) {
      this.streamValue = this.$route.query.stream;
    }

    this.getActivities();

    await this.fetchCoreSkills(this.centerId).then(res => {
      this.coreSkillOptions = _.map(res.data.data, o => {
        return {
          label: o.name,
          value: o._id
        };
      });
      this.coreSkillOptions.unshift({ label: "All", value: "" });
    });

    await this.getFilterTeacherList().then(res => {
      this.teacherOptions = _.map(res.data.data, o => {
        return {
          label: o.fullName,
          value: o._id
        };
      });
      this.teacherOptions.unshift({ label: "All", value: "" });
    });

    await this.fetchStreams(this.centerId).then(res => {
      this.streamOptions = _.map(res.data.data, o => {
        return {
          label: o.name,
          value: o._id
        };
      });
      this.streamOptions.unshift({ label: "All", value: "" });
    });

    await this.fetchWaysOfLearning(this.centerId).then(res => {
      this.waysOfLearningOptions = _.map(res.data.data, o => {
        return {
          label: o.name,
          value: o._id
        };
      });
      this.waysOfLearningOptions.unshift({ label: "All", value: "" });
    });

    this.initialLoad = false;

    //console.log(this.$store.state.coreSkill.coreSkillsOptions);
    //teacher=${leadTeacher._id}&displayView=List||Matrix&status=completed`&learning-story-type=relational
  },
  computed: {
    coreSkills() {
      return this.$store.state.coreSkill.coreSkills;
    },
    waysOfLearnings() {
      return this.$store.state.waysOfLearning.waysOfLearning;
    },
    streams() {
      return this.$store.state.stream.streams;
    }
  },
  watch: {
    coreSkillFilter(val) {
      this.coreSkillValue = val.value;
      this.getActivities();
    },
    streamFilter(val) {
      this.streamValue = val.value;
      this.getActivities();
    },
    waysOfLearningFilter(val) {
      this.waysOfLearningValue = val.value;
      this.getActivities();
    },
    teacherFilter(val) {
      this.teacherId = val.value;
      this.getActivities();
    },
    startDate(obj) {
      this.disabledDates = {to:this.startDate}
      if ("" != this.startDate && "" != this.endDate) {
        if (!this.initialLoad) {
          this.getActivities();
        }

        this.timePeriod = this.monthDiff + " months";
      }
    },
    endDate(obj) {
      this.disabledDates = {to:this.startDate}
      if ("" != this.startDate && "" != this.endDate) {
        if (!this.initialLoad) {
          this.getActivities();
        }
        this.timePeriod = this.monthDiff + " months";
      }
    }
  }
};
</script>
<style lang="scss">
.new {
  background: #76c68f;
}

.complete {
  background: #d1d1d1;
}

.follow-up {
  background: #f3d16c;
}

.fast-check-popup.fullscreen {
  .h2 {
    margin-bottom: 0;
  }

  .h3 {
    color: #222222;
  }

  .overflow-auto {
    overflow: hidden !important;
    overflow-x: auto !important;
  }

  .vs-popup {
    width: 75%;

    .vs-popup--content {
      padding: 30px;
    }

    .vs-popup--header {
      height: 0;

      .vs-popup--title {
        h3 {
          display: none;
        }
      }
    }

    .vs-popup--close {
      transform: none !important;
      background: none !important;
      box-shadow: none;
      position: relative;
      top: 35px;
      right: 20px;
      font-size: 25px;
      color: #2b2d3d;

      &:hover {
        color: #2d9ad5;
      }
    }
  }
}

.fastcheck-list {
  display: flex;
  margin: 30px 0 20px;
  border-bottom: 1px solid #d8d8d8;
  width: fit-content;
  min-width: 100%;

  li {
    vertical-align: top;
    position: relative;
    min-width: 200px;
    width: 200px;
    max-width: none;
    text-align: center;
    margin-right: 0;
    padding: 0 5px;
    @media (min-width: 1480px) {
      padding: 0 30px;
      margin: 0 -10px;
    }

    &:after {
      content: "";
      background-image: url("../../../../assets/images/personhood/line.png");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      position: absolute;
      height: 30px;
      bottom: -22px;
      left: 0;
      width: 100% !important;
      opacity: 0;
    }

    span {
      font-size: 16px;
      line-height: 22px;
      color: #606060;
      display: block;
      text-align: center;
      margin-bottom: 12px;
      @media (min-width: 1640px) {
        font-size: 18px;
        line-height: 25px;
      }
    }

    &.active {
      &:after {
        opacity: 1;
      }

      .con-vs-avatar.large {
        border: none;
      }

      span {
        font-weight: 600;
        color: #289ad5;
      }
    }
  }
}

.rank-title {
  display: flex;
  align-items: center;
  margin-top: 7px;
  font-size: 16px;
  line-height: 30px;
  @media (min-width: 1480px) {
    font-size: 18px;
    line-height: 36px;
  }

  li {
    display: inline-flex;
    align-items: center;
    margin-right: 40px;

    .tag {
      margin-right: 15px;
    }
  }
}

.tag {
  width: 21px;
  height: 21px;
  border-radius: 50%;
  display: inline-block;

  &.average-tag {
    background: #74cac2;
  }

  &.trend-tag {
    background: #27378e;
  }
}

.rank-col {
  margin-top: 30px;
  flex-wrap: wrap;
  font-size: 16px;
  line-height: 20px;
  @media (min-width: 901px) {
    padding-right: 50px;
    font-size: 18px;
    line-height: 25px;
  }
  @media (min-width: 1480px) {
    padding-right: 250px;
    font-size: 20px;
    line-height: 27px;
  }
}

.rank-bar {
  background: #ededed;
  height: 6px;
  border-radius: 3px;
  display: flex;
  width: 100%;
  margin: 30px 0 0;
  @media (min-width: 901px) {
    margin: 50px 0;
  }

  li {
    width: calc(100% / 5);
    position: relative;

    .tag {
      position: absolute;
      top: -8px;
      right: 0;
    }
  }
}

.rank-link-col {
  .font-semibold {
    text-decoration: underline;
    font-size: 16px;
    line-height: 22px;
    color: #289ad5;
    margin-top: 20px;
    @media (min-width: 1480px) {
      font-size: 18px;
      line-height: 25px;
    }
  }
}

.fast-check-popup1 {
  .vs-popup {
    .vs-popup--content {
      padding-top: 0 !important;
    }

    .vs-popup--close {
      z-index: 20;
    }
  }

  .vx-card {
    box-shadow: none !important;

    .vx-card__body {
      padding: 0 !important;
    }
  }

  p {
    font-size: 16px;
    line-height: 22px;
    margin: 10px 0 40px;
  }

  .range {
    width: 100%;
  }
}
</style>
